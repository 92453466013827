import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Divider } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { getCurrencySymbol, toLocaleString } from '../../collums-components/helpers/index';

import { SERVICE, STAFF, TIME } from '../../constants/steps';
import {
    changeStep,
    updateAppointments,
    changeService,
    clearLocation,
    setCurrentDate,
    setCurrentTimeStep,
    chooseTime,
    setCurrentCategory
} from '../../redux/actions/book-appointment';
import {
    getCurrentTime,
    getCurrentLocation,
    getCurrentStaff,
    getCurrentDate,
    getCurrentAppointments
} from '../../redux/selectors/book-appointment';
import { stepStyles } from './styles';
import { getAddress } from './../../utils/helpers/index';
import { getLocationItem } from '../../collums-constants/utils';

export default function BookingAppointmentDetails() {
    const classes = makeStyles(theme => stepStyles(theme))();

    const dispatch = useDispatch();

    const currentLocation = useSelector(getCurrentLocation);
    const currentStaff = useSelector(getCurrentStaff);
    const currentTime = useSelector(getCurrentTime);
    const currentDate = useSelector(getCurrentDate);
    const currentAppointments = useSelector(getCurrentAppointments);
    const isLinked = useSelector(state => state.bookAppointment.isLinked);
    const hasLinked = useSelector(state => state.bookAppointment.linkedAppointments);

    const renderPrice = current => {
        if (current.service?.defaultPrice === Infinity) {
            return null;
        }
        if (current.staff?.grossPrice) return toLocaleString(current.staff.grossPrice);
        return toLocaleString(current.service?.defaultPrice);
    };

    const goTo = step => {
        dispatch(changeStep(step));
    };

    const handleChangeTime = () => {
        dispatch(setCurrentDate(null));
        dispatch(chooseTime(null));
        dispatch(setCurrentTimeStep(null));
        goTo(TIME);
    };

    const handleChangeService = index => {
        const tempCurrAppts = currentAppointments;
        tempCurrAppts.splice(index, 1);
        handleChangeTime();
        dispatch(updateAppointments(tempCurrAppts));
        dispatch(setCurrentCategory(null));
        goTo(SERVICE);
    };

    const handleDeleteService = index => {
        const tempCurrAppts = currentAppointments;
        tempCurrAppts.splice(index, 1);
        dispatch(updateAppointments(tempCurrAppts));
    };

    const handleDeletePractitioner = index => {
        const tempCurrAppts = currentAppointments;
        dispatch(changeService(tempCurrAppts[index].service));
        tempCurrAppts.splice(index, 1, {
            ...tempCurrAppts[index],
            staff: null
        });
        handleChangeTime();
        dispatch(updateAppointments(tempCurrAppts));
        goTo(STAFF);
    };

    const formatDate = value => {
        return `${format(value, 'HH:mma')}`;
    };

    const canShowTotal = useMemo(() => {
        if (currentAppointments?.length) {
            return currentAppointments.every(appt => appt.service?.showPriceOnline);
        }
        return false;
    }, [currentAppointments]);

    const showTotal = () => {
        const total = currentAppointments.reduce(
            (acc, curr) =>
                (acc += curr.staff?.grossPrice
                    ? curr.staff?.grossPrice
                    : curr.service?.defaultPrice),
            0
        );
        if (isNaN(total)) {
            return '';
        }

        return (!currentStaff ? 'From: ' : '') + getCurrencySymbol() + parseFloat(total).toFixed(2);

    };

    const printDuration = loc => {
        const durations = loc.staffs.map(staff => {
            if (staff.duration) {
                return staff.duration;
            }
            return loc?.defaultDuration;
        });
        const minDuration = Math.min(...durations);
        const maxDuration = Math.max(...durations);
        return (minDuration !== maxDuration ? 'From ' : '') + minDuration;
    };

    return (
        <>
            {currentLocation && (
                <div className={classes.detailContainer}>
                    {currentLocation.accountName && (
                        <>
                            <div className={classes.detailItem}>
                                <b>{currentLocation.accountName}</b>
                                <div className={classes.row}>
                                    <span>
                                        {getAddress(currentLocation)}
                                    </span>
                                    {!(isLinked || (hasLinked && hasLinked.length)) &&/* eslint-disable-next-line */
                                        <a href="#" onClick={() => dispatch(clearLocation())}>
                                            change
                                        </a>}
                                </div>
                            </div>
                            <Divider/>
                        </>
                    )}
                    {currentAppointments.map((current, index) => (
                        current.service && (
                            <React.Fragment key={index}>
                                <div className={classes.detailItem}>
                                    <div className={classes.row}>
                                        <b>{current.service.onlineName ? current.service.onlineName : current.service.name}</b>
                                        {current.service.showPriceOnline && (
                                            <b>
                                                {!current.staff && 'From: '}
                                                {renderPrice(current)}
                                            </b>
                                        )}
                                    </div>
                                    <div className={classes.row}>
                                        {current.service.showDurationOnline && (
                                            <span>
                                                {(() => {
                                                    if (current.staff && current.staff.duration) {
                                                        return current.staff.duration;
                                                    }

                                                    return current.service.locations
                                                        ? printDuration(getLocationItem(current.service, currentLocation.id))
                                                        : printDuration(current.service.location);
                                                })()}
                                            mins
                                            </span>
                                        )}
                                        <div>
                                            {!(isLinked || (hasLinked && hasLinked.length)) && /* eslint-disable-next-line */
                                            <a href="#" onClick={() => handleChangeService(index)}>
                                                change
                                            </a>}
                                            {index > 0 && !(isLinked || (hasLinked && hasLinked.length)) && (
                                                <DeleteOutlinedIcon
                                                    onClick={() => handleDeleteService(index)}
                                                    className={classes.deleteBtn}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {current.staff !== null && (
                                    <div className={classes.detailItem}>
                                        <div className={classes.row}>
                                            <b>With {current.staff ? current.staff.displayName : 'any practitioner'}</b>
                                            {!(isLinked || (hasLinked && hasLinked.length)) && /* eslint-disable-next-line */
                                            <a href="#" onClick={() => handleDeletePractitioner(index)}>
                                                change
                                            </a>
                                            }
                                        </div>
                                    </div>
                                )}
                                <Divider/>
                            </React.Fragment>)
                    ))}
                    {currentDate && (
                        <div className={classes.detailItem}>
                            <div className={classes.row}>
                                <b>{format(currentDate, 'EEEE do MMMM yyyy')}</b>
                                {/* eslint-disable-next-line */}
                                <a href="#" onClick={() => handleChangeTime()}>
                                    change
                                </a>
                            </div>

                            {currentTime && (
                                <>
                                    <span>{formatDate(currentTime)}</span>
                                    <Divider/>
                                </>
                            )}
                        </div>
                    )}
                    {currentAppointments && (
                        <div className={classes.detailItem}>
                            <div className={classes.row}>
                                <b>Total</b>
                                {
                                    canShowTotal && (<b>
                                        {showTotal()}
                                    </b>)
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
